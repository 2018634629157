import styled from "styled-components";
import { Button } from "@sablier/v2-components/molecules";
import { Meta } from "@sablier/v2-components/organisms";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Page as PagePartial } from "~/client/components/organisms";
import { pages } from "~/client/constants";
import Navigation from "./Navigation";
import Notification from "./Notification";

const Container = styled.div`
  ${(props) => props.theme.styles.contained}
  & {
    width: 100%;
    padding-bottom: 100px;

    *[data-component="table"] > div > *[data-component="header"] {
      border-top-left-radius: 0px !important;
    }

    *[data-component="filter"] {
      + * {
        *[data-component="table"] > div > *[data-component="header"] {
          border-top-right-radius: 0px !important;
        }
      }
    }
  }
`;

const ButtonV1 = styled(Button)`
  width: ${(props) => props.theme.sizes.buttonMini};
  padding-inline: 0;
`;

const Actions = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    column-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
  }
`;

const Page = styled(PagePartial)`
  ${(props) => props.theme.medias.maxLG} {
    ${Container} {
      padding-bottom: 40px;
      *[data-component="table"] > div > *[data-component="body"] {
        border-top-right-radius: 10px;
      }

      ${ButtonV1} {
        display: none;
      }

      *[data-component="filter"] {
        + * {
          *[data-component="table"] > div > *[data-component="body"] {
            border-top-right-radius: 0px;
          }
        }
      }
    }
  }

  ${(props) => props.theme.medias.max(840)} {
    ${Actions} {
      position: relative;
      & > *:not(:last-child) {
        position: absolute;
        top: -70px;
        right: calc(172px + calc(1 / 2 * ${(props) => props.theme.sizes.edge}));
        p {
          white-space: nowrap;
        }
      }
      & > *:last-child {
        position: absolute;
        top: -70px;
        right: 0;
        p {
          white-space: nowrap;
        }
      }
    }

    ${Container} {
      *[data-component="table"] > div > *[data-component="header"] {
        border-radius: 0;
      }
      *[data-component="table"] > div > *[data-component="body"] {
        border-top-right-radius: 0px;
      }
    }
  }

  ${(props) => props.theme.medias.maxSM} {
    ${Actions} {
      & > *:not(:last-child) {
        right: calc(108px + calc(1 / 2 * ${(props) => props.theme.sizes.edge}));
      }
    }
  }

  ${(props) => props.theme.medias.maxXXS} {
    ${Actions} {
      & > *:not(:last-child) {
        display: none;
      }
    }
  }
`;

function Dashboard() {
  const { t } = useT();

  return (
    <Page>
      <Meta.Head title={t(pages.dashboard.locales)} />
      <Notification />
      <Container>
        <Navigation />
      </Container>
    </Page>
  );
}

export default Dashboard;
