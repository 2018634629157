import { useMemo } from "react";
import styled from "styled-components";
import { Header } from "@sablier/v2-components/molecules";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { pages } from "~/client/constants";

const Wrapper = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    width: 100%;
    padding-top: calc(${(props) => props.theme.sizes.edge} * 0);
  }
`;

function Navigation() {
  const { t } = useT();

  const path = useMemo(
    () => [
      {
        title: t(pages.dashboard.locales),
        toPage: pages.dashboard.builder(),
      },
    ],
    [t],
  );

  return (
    <Wrapper>
      <Header
        path={path}
        helper={_.capitalize(t("words.welcome"))}
        title={t(pages.dashboard.localesHeading)}
        titleShort={t(pages.dashboard.locales)}
      />
    </Wrapper>
  );
}

export default Navigation;
