import styled from "styled-components";
import {
  ArrowRightIcon,
  ChatBubbleBottomCenterIcon,
} from "@heroicons/react/24/outline";
import { Internal } from "@sablier/v2-components/atoms";
import { Icon } from "@sablier/v2-components/molecules";
import { useT } from "@sablier/v2-locales";
import { pages } from "~/client/constants";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    width: 100%;
    height: 52px;
    border-bottom: 2px solid ${(props) => props.theme.colors.dark250};
    border-radius: 0;
    background-color: ${(props) => props.theme.colors.dark050};
  }

  ${(props) => props.theme.medias.maxSM} {
    height: auto;
    padding-top: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    padding-bottom: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
  }
`;

const Container = styled.div`
  ${(props) => props.theme.styles.contained}
  ${(props) => props.theme.styles.row}
  & {
    width: 100%;
    height: 100%;
  }
`;

const Target = styled(Internal)`
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    height: 100%;
    margin-left: -9px;
    color: ${(props) => props.theme.colors.gray100};
    cursor: pointer;

    &:after {
      --highlight-end: ${(props) => props.theme.colors.purpleTransparent};
      --highlight: ${(props) => props.theme.colors.purple};
      position: absolute;
      left: -20px;
      bottom: -2px;
      content: "";
      z-index: ${(props) => props.theme.sizes.zIndexOver};
      width: calc(2 * 20px + ${(props) => props.theme.sizes.buttonMini});
      height: 2px;
      background: linear-gradient(
        90deg,
        var(--highlight-end),
        var(--highlight) 50%,
        var(--highlight-end)
      );
    }

    &:hover,
    &:active {
      color: ${(props) => props.theme.colors.gray200};
    }
  }
`;

const Box = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    flex-shrink: 0;
    justify-content: center;
    width: ${(props) => props.theme.sizes.buttonMini};
    height: ${(props) => props.theme.sizes.buttonMini};
    border-radius: 8px;
    background: ${(props) => props.theme.gradients.purple};
  }
`;

const Title = styled.div`
  & > p {
    ${(props) => props.theme.styles.textParagraph}
  }
`;

const Action = styled.div`
  & > * {
    --size: 12pt;
    border-radius: 50%;
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxSM} {
    ${Target} {
      &:after {
        display: none;
      }
    }
  }
`;

function Notification() {
  const { t } = useT();

  return (
    <Wrapper>
      <Container>
        <Target to={pages.drops.dashboard.builder()}>
          <Box>
            <Icon value={ChatBubbleBottomCenterIcon} />
          </Box>
          <Title>
            <p>{t("descriptions.notification")}</p>
          </Title>
          <Action>
            <Icon value={ArrowRightIcon} />
          </Action>
        </Target>
      </Container>
    </Wrapper>
  );
}

export default Notification;
